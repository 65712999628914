// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: 'AIzaSyAdIj1Aka5c7XG4ykrzGc8S9Jg5zNFSRLY',
    authDomain: 'wilderman-icbt-9ce6a.firebaseapp.com',
    databaseURL: 'https://wilderman-icbt-9ce6a.firebaseio.com',
    projectId: 'wilderman-icbt-9ce6a',
    storageBucket: 'wilderman-icbt-9ce6a.appspot.com',
    messagingSenderId: '124916513499',
    appId: '1:124916513499:web:a66fb740492076340deafe',
    measurementId: 'G-ZFB09Z1YKH'
  }
  /*firebaseConfig: {
    apiKey: "AIzaSyB6gznjVfMjB8BLZCxGgzSNj75VDyEzm-w",
    authDomain: "wm-fb-poc.firebaseapp.com",
    databaseURL: "https://wm-fb-poc.firebaseio.com",
    projectId: "wm-fb-poc",
    storageBucket: "wm-fb-poc.appspot.com",
    messagingSenderId: "321867705398",
    appId: "1:321867705398:web:659f6eb33446644b02fc97"
  }*/
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
