import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IntroComponent } from './intro/intro.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';


import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { environment } from '../environments/environment.prod';
import { AuthenticationService } from './shared/authentication.service';
import { IcbtComponent } from './icbt/icbt.component';
import { AuthModulesGuard } from './shared/auth-modules.guard';
import { TermsComponent } from './terms/terms.component';
import { LandingComponent } from './landing/landing.component';
import { MomentModule } from 'ngx-moment';
import { SharedModule } from './shared/shared.module';


@NgModule({
  declarations: [
    AppComponent,
    IntroComponent,
    AboutComponent,
    ContactComponent,
    IcbtComponent,
    TermsComponent,
    LandingComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    MomentModule,
    SharedModule
    // StripeModule.forRoot('pk_test_WJCFUIrxvKynU2VCcxWDZrZg00mJSkvUnE')
  ],
  providers: [AuthenticationService, AuthModulesGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
