import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable, from, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { FirebaseApp } from '@angular/fire';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  user$: Observable<firebase.User>;
  user: firebase.User;

  constructor(private angularFireAuth: AngularFireAuth,
              private firebase: FirebaseApp) {
    this.user$ = angularFireAuth.authState;
    this.user$.subscribe(user => {
      if (user && user.emailVerified) {
        this.user = user;
      } else {
        this.user = null;
      }
    });
  }

  async changePassword(newPassword) {
    if (this.user) {
      return this.user.updatePassword(newPassword);
    } else {
      return Promise.reject('User not signed in.');
    }
  }

  authUserUid() {
    return this.user$.pipe(map((user: any) => user ? user.uid : null));
  }

  authUserEmail() {
    return this.user$.pipe(map((user: any) => user ? user.email : null));
  }

  async createUserWithEmailAndPassword(email: string, password: string) {
    try {
      const res = await this.angularFireAuth
        .auth
        .createUserWithEmailAndPassword(email, password);
      const emailSent = await res.user.sendEmailVerification();
      return Promise.resolve(res.user.uid);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async logIn(email: string, password: string) {
    return this.angularFireAuth.auth
      .signInWithEmailAndPassword(email, password);
  }

  async logOut() {
    return this.angularFireAuth.auth.signOut();
  }

  async sendPasswordResetEmail(email: string) {
    return this.firebase.auth().sendPasswordResetEmail(email);
  }
  /*
    CODES:
    auth/invalid-email - Thrown if the email address is not valid.
    auth/user-disabled - Thrown if the user corresponding to the given email has been disabled.
    auth/user-not-found - Thrown if there is no user corresponding to the given email.
    auth/wrong-password - Thrown if the password is invalid for the given email,
      or the account corresponding to the email does not have a password set.
    */
   formatAuthSignInWithEmailAndPasswordErrorCode(errorCode: string) {
    switch (errorCode) {
      case 'auth/user-not-found': {
        return 'User email not found.';
      } case 'auth/user-disabled': {
        return 'This user has been disabled.';
      } case 'nvalid-email': {
        return 'Invalid email address.';
      } case 'auth/wrong-password': {
        return 'invalid password.';
      } default: {
        return 'User sign in authentication error.';
     }
    }
  }
}
