import { Injectable } from '@angular/core';
import { AuthenticationService } from '../shared/authentication.service';
import { User } from './user.interface';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private authService: AuthenticationService,
              private firestore: AngularFirestore) { }

  async newUser(password: string, user: User) {
    try {
      const uid = await this.authService.createUserWithEmailAndPassword(user.email, password);
      const addUser = await this.firestore
              .collection('users').doc(uid)
              .set(user);
      return addUser;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getUserProfile(uid: string): Promise<any> {
    try {
      const user = await this.firestore.collection('users').doc(uid).get().toPromise();
      if (user.exists) {
          return Promise.resolve(user.data());
        } else {
          return Promise.reject('User does not exist.');
        }
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async updateUserProfile(user: User, uid: string): Promise<any> {
    if (uid) {
      return this.firestore.collection('users').doc(uid).update(user);
    } else {
      return Promise.reject();
    }
  }

  async setLanding(uid: string) {
    if (uid) {
      return this.firestore
        .collection('users')
        .doc(uid)
        .set({landing: true}, {merge: true});
    } else {
      return Promise.reject();
    }
  }
}
