export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: 'AIzaSyAdIj1Aka5c7XG4ykrzGc8S9Jg5zNFSRLY',
    authDomain: 'wilderman-icbt-9ce6a.firebaseapp.com',
    databaseURL: 'https://wilderman-icbt-9ce6a.firebaseio.com',
    projectId: 'wilderman-icbt-9ce6a',
    storageBucket: 'wilderman-icbt-9ce6a.appspot.com',
    messagingSenderId: '124916513499',
    appId: '1:124916513499:web:a66fb740492076340deafe',
    measurementId: 'G-ZFB09Z1YKH'
  }
};
