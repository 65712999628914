import { Component, OnInit } from '@angular/core';
import {AuthenticationService} from '../../../shared/authentication.service';
import {UserService} from '../../user.service';

@Component({
  selector: 'app-instructions',
  templateUrl: './instructions.component.html',
  styleUrls: ['./instructions.component.css']
})
export class InstructionsComponent implements OnInit {
  email: string;

  constructor(authService: AuthenticationService, userService: UserService) {
    if (authService.user) {
      const userProfile = userService.getUserProfile(authService.user.uid);
      userProfile.then(user => {
        this.email = user && user.email;
      });
    }
  }

  ngOnInit(): void {
  }

}
