import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'validSubscription'
})
export class ValidSubscriptionPipe implements PipeTransform {

  transform(jsonUser: unknown, ...args: unknown[]): unknown {
    return  jsonUser && jsonUser['paid']? moment(jsonUser['paid']).add(1, 'y').isAfter(moment.now()): false;

  }

}
