import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IntroComponent } from './intro/intro.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { IcbtComponent } from './icbt/icbt.component';
import { AuthModulesGuard } from './shared/auth-modules.guard';
import { TermsComponent} from './terms/terms.component';
import { InstructionsComponent } from './users/my-account/instructions/instructions.component';
import {LandingComponent} from './landing/landing.component';
import { AuthUserGuard } from './shared/auth-user.guard';


const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'icbt' },
  { path: 'icbt', component: IcbtComponent },
  { path: 'intro', component: IntroComponent },
  { path: 'modules', canActivate: [AuthModulesGuard], loadChildren: () =>
            import('./lessons/lessons.module')
            .then(m => m.LessonsModule)},
  { path: 'users', loadChildren: () =>
            import('./users/users.module')
            .then(m => m.UsersModule)},
  { path: 'about', component: AboutComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'terms-conditions', component: TermsComponent},
  { path: 'instructions', component: InstructionsComponent},
  { path: 'landing', canActivate: [AuthUserGuard], component: LandingComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
