import { Component, OnInit } from '@angular/core';
import {AuthenticationService} from '../shared/authentication.service';

@Component({
  selector: 'app-intro',
  templateUrl: './intro.component.html',
  styles: []
})
export class IntroComponent implements OnInit {

  constructor(private authService: AuthenticationService) {}

  ngOnInit() {
  }

  get userLoggedIn() {
    return !!this.authService.user;
  }
}
