import { Component, ɵɵNgOnChangesFeature, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { FirebaseApp } from '@angular/fire';
import { UserService } from './users/user.service';
import { User } from './users/user.interface';
import { Router } from '@angular/router';
import { AuthenticationService } from './shared/authentication.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {
  MSG1 = 'You need to register and purchase the program to get access.';
  MSG2 =  'Please purchase or re-new the program to get access.';
  title = 'wilderman';
  user: Promise<User>;
  uid = null;

  constructor(private authService: AuthenticationService,
              private userService: UserService,
              private router: Router) {}

  ngOnInit() {
    this.authService.user$.subscribe(user => {
      if (user) {
        this.user = this.userService.getUserProfile(user.uid);
        this.uid = user.uid;
       
      } else { 
        this.user = null;
      }
    });
  }

  logout() {
    this.authService.logOut().then(() => {
      this.router.navigate(['/icbt']);
    });
  }

}
