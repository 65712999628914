import { Injectable } from '@angular/core';
import { CanActivate, UrlTree, Router } from '@angular/router';
import { Observable, empty } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { of } from 'rxjs';

import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class AuthModulesGuard implements CanActivate {
  constructor(private angularFireAuth: AngularFireAuth,
    private firestore: AngularFirestore) {}

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const user$ = uid => uid ? this.firestore.collection('users').doc(uid).get(): empty();
    const auth$ = this.angularFireAuth.authState;

    return auth$.pipe(
      mergeMap(auth => user$(auth ? user$(auth.uid) : of(null)).pipe(

        map(user => { 
          return user.data().paid && moment(user.data().paid).add(1, 'y').isAfter(moment.now());
        })
      )))
  }
}
