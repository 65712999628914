import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../shared/authentication.service';

@Component({
  selector: 'app-icbt',
  templateUrl: './icbt.component.html',
  styles: []
})
export class IcbtComponent implements OnInit {

  constructor(private authService: AuthenticationService) { }

  ngOnInit() {
  }

  get userLoggedIn() {
    return !!this.authService.user;
  }

}
