import { Component, OnInit } from '@angular/core';
import {AuthenticationService} from '../shared/authentication.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css']
})
export class LandingComponent implements OnInit {

  constructor(/*authService: AuthenticationService, route: Router*/) {
    // setTimeout( () => {
    //   if (authService.user) {
    //     route.navigateByUrl('/intro').then(v => v);
    //   }
    // }, 1500);
  }

  ngOnInit(): void {
  }

}
